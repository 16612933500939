import { Controller } from 'stimulus';
import './jquery.slider.js';

export default class extends Controller {
  connect() {
    $(this.scope.element).bjqs({
      width: 1172,
      height: 300,
      responsive: true,
      showcontrols: false,
      showmarkers: true,
      randomstart: false,
      automatic: true,
      animspeed: 8000,
      hoverpause: false
    });
  }
}
